.dark-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #333;
    color: #fff;
  }
  
  .dark-table th, .dark-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #555;
  }
  
  .dark-table tbody tr:nth-child(even) {
    background-color: #444;
  }
  
  .dark-table tbody tr:hover {
    background-color: #555;
  }


  .striped-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .striped-table th, .striped-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .striped-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  

  .hover-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .hover-table th, .hover-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .hover-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  