.radiobtn-list-container {
    
    /* flex-direction: column; */
    gap: 10px;
    margin-left: 20px;
  }
  .checked-item,
  .not-checked-item {
    margin-left: 5px;
  }

  .cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:99;
   
}

/* Loading Spinner */
@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
  
}

.cover-spin::after {
  
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:80px;height:80px;
  border-style:solid;
  border-color:#1DA760;
  border-top-color:transparent;
  border-width: 6px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite ,rotate 1.5s linear infinite;
  content:'';
}

.spin-waiting-msg {
  text-align: center;
  padding-top: 340px;
  /* font-family:  "Rokkitt", serif;  */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgb(75, 57, 57)
}


.autocomplete {
  position: relative;
}

.autocomplete input {
  /* Your input styles here */
  /* font-family: "Rokkitt", serif; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.autocomplete-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  /* font-family: "Rokkitt", serif; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.autocomplete-option {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  /* font-family: "Rokkitt", serif; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.recipe-name {
  font-weight: bold;
  font-family: "Rokkitt", serif;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  /* Recipe name styles here */
}

.food-type-category {
  color: #777;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /* font-family: "Rokkitt", serif !important; */
    /* Food type and category styles here */
}


.slide-in {
  animation: slide-in 0.8s ease-in-out;
  
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}


/***
Custom label and badges
***/
.label, 
.badge {
  font-weight: 300;
  font-weight: bold;
  color: black;
  padding: 5px;
  border-radius: 10px;
}

.label-success, 
.badge-success {
  background-color: #3cc051;
  background-image: none !important;
  text-shadow: none !important;
}

.label-warning, 
.badge-warning {
  background-color: #fcb322;
  background-image: none !important;
  text-shadow: none !important;
}

.label-important, 
.badge-important {
  background-color: #ed4e2a;
  background-image: none !important;
  text-shadow: none !important;
}

.label-info, 
.badge-info {
  background-color: #57b5e3;
  background-image: none !important;
  text-shadow: none !important;
}

.label-mini {
  font-size: 11px;
  font-weight: 300;
}

.label-default  {
  background-color: #999 !important;
  text-shadow: none !important;
}

.mini-button {
  padding: 2px;
    font-size: small;
}

.search-bar-container {
  position: relative;
  
 
}
/* .search-input {
  padding: 8px;
  width: 60%;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.search-recipe-input {
 font-family:'Rokkitt !important';
 

}
.search-icon {
  /*
  position: absolute;
  top: 50%;
  right: 8px; 
  transform: translateY(-50%);
  cursor: pointer;
  padding-right: 11%;
  */
 
  transform: translateY(-191%);
  cursor: pointer;
  padding-right: 6%;
  float: right;
}

/* ------------------ */

/* body {
  font-family: Helvetica, sans-serif;
} */

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

/* .new-form-control{
  border-radius: '5px !important';
  height: '70%  !important';
  width: '100%  !important';
  border: '2px solid #d1ddcd  !important';
  color: '#666666  !important';
  font-family:'"Rokkitt", serif  !important';
  padding: '0px !important';
} */

/* .dropdown-container{
  border-radius: 5px !important;
  color: #666666 !important;
  border: 2px solid #de7a72 !important;
  padding: 0.5rem  !important;
  font-size: 1rem !important;
  font-family: "Rokkitt", serif;
  width: 100%  !important;
}
.multi-select{
  border: 0px !important;
 
} */

.modal-header {
  /* background-color: rgb(248, 248, 248); */
  background-color: #edeaea;
  color: black;
  border-bottom: 1px solid rgb(194 173 173) !important
}

.left{
  float: left;
  margin-left: 2px;
}
.right{
  float: right;
  margin-right: 2px;
}
.center{
  margin-right: 50%;
  float: right;
}

.sticky-buttons {
  position: fixed;
  bottom: 0;
  width: 55%;
  padding: 15px;
  text-align: center;
}

.sticky-buttons button {
  margin: 0 10px;
}

.fontRokkitt{
  font-family: "Rokkitt", serif;
}

.fontRoboto{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}



